<template>
	<div data-component="upload-deal-analysis-modal">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="uploadForm"
		>
			<lesson-resource-item-base
				v-if="getShowDealAnalysisFile"
				:resource="getDealAnalysis"
			/>
			<upload
				v-else
				name="Deal analysis file"
				:rules="rules"
				:helpText="helpText"
				:form.sync="formData"
			/>
			<actions
				:actions="getUploadActions"
				:isWaiting="isWaiting"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import LessonResourceItemBase from '@/components/lessons/LessonResourceItemBase';
	import Upload from '@/components/uploads/Upload';
	import Actions from '@/components/ui/Actions';
	import actionClick from '@/mixins/actionClick';
	import routeParams from '@/mixins/routeParams';
	import api from '@/services/api';
	import { dealAnalysisType } from '@/consts';
	import { v4 as uuid } from 'uuid';

	export default {
		components: {
			LessonResourceItemBase,
			Upload,
			Actions
		},
		mixins: [actionClick, routeParams],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			formData: undefined,
			rules: {
				required: true,
				lessonResources: 'pbn|lin|pdf|doc|docx|ppt|pptx|pps|mp4'
			},
			helpText: [
				'You can upload a pbn, lin, pdf, doc, docx, ppt, pptx, pps or mp4 file'
			],
			isWaiting: undefined
		}),
		computed: {
			getDealMedia () {
				return this.deal?.media || [];
			},
			getDealAnalysis () {
				return this.getDealMedia.find(item => item.config?.type === dealAnalysisType);
			},
			getShowDealAnalysisFile () {
				return !!this.getDealAnalysis;
			},
			getUploadActions () {
				return {
					primary: [
						(
							this.getShowDealAnalysisFile ? {
								text: 'Delete',
								colour: 'red',
								variant: 'red',
								btnId: 'delete',
								actionId: 'deleteDealAnalysis'
							} : {
								text: 'Save',
								btnId: 'uploadForm',
								variant: 'red',
								type: 'submit'
							}
						)
					],
					secondary: [
						{
							text: 'Close',
							actionId: 'close'
						}
					]
				};
			}
		},
		methods: {
			close () {
				this.$emit('close');
			},
			async uploadAnalysisFile (formData) {
				const config = {
					type: dealAnalysisType,
					uploadUuid: uuid()
				};

				formData.append('config', JSON.stringify(config));

				const response = await api.deals.createMediaByDealId({
					dealId: this.deal.id,
					media: formData
				});

				if (response) {
					this.deal.media = [...this.deal.media, response];

					this.isWaiting = undefined;
					this.$store.commit('ui/showNotification', {
						notification: 'Upload complete 👍'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			},
			async uploadForm () {
				const isValid = await this.$refs.form.validate();
				if (!isValid) {
					return;
				}
				this.isWaiting = 'uploadForm';
				await this.uploadAnalysisFile(this.formData);
			},
			async deleteDealAnalysis () {
				if (this.getDealAnalysis && confirm('Are you sure?')) {
					this.isWaiting = 'delete';
					const response = await api.deals.deleteMediaByDealIdAndMediaId({
						dealId: this.deal.id,
						mediaId: this.getDealAnalysis.id
					});

					if (!response) {
						return false;
					}

					this.deal.media = this.deal.media.filter((item) => {
						return item.id !== this.getDealAnalysis.id;
					});

					this.isWaiting = undefined;
					this.$store.commit('ui/showNotification', {
						notification: 'Successfully deleted.'
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='upload-deal-analysis-modal'] {
		form {
			margin-bottom: 0;
			::v-deep [data-component='upload-field'] {
				margin: 0;
			}
		}

		[data-component='actions'] {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-bottom: 0;

      ::v-deep [data-element="actions-primary"] {
        margin: 0 !important;
      }

      ::v-deep [data-element="actions-secondary"] {
        margin: 0 !important;
      }

      ::v-deep [data-component="btn"] {
        width: 100%;
        margin: 0 !important;
      }
		}
	}

</style>
